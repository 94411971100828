<template>
  <div class="title-bar">
    <div class="title">
      <slot />
    </div>
    <div class="title-bar-actions">
      <slot name="button" />
    </div>
  </div>
</template>
<script setup lang="ts"></script>
